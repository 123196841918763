import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { IdAndName } from '@src/interfaces/index'

export enum MeetingStatus {
  Completed = 'completed',
  Scheduled = 'scheduled',
}

export enum EmployeeRelation {
  DirectReport = 'direct report',
  FunctionalReport = 'functional report',
  LineManager = 'line manager',
  FunctionalManager = 'functional manager',
}

export interface MeetingCompanionInterface {
  id: number
  employee: EmployeeOptionInterface
  employee_relation: EmployeeRelation
  next_meeting_date: string | null
  days_since_last_meeting: number | null
  can_setup_meeting: boolean
  latest_meeting_id: number | null
  next_meeting?: NextMeetingInterface
}

export interface NextMeetingInterface {
  object_id: number
  feedback_description: string
  feedback_link: string
  feedback_event_link: string
  feedback_title: string
}

export interface MeetingInterface {
  id: number
  start: string
  end: string
  status: MeetingStatus
  employee: EmployeeOptionInterface
  link: string
}

export interface MeetingWithManagersStats {
  last_lm_meeting_date: string | null
  last_fm_meeting_date: string | null
}

export interface MeetingWithCompanionDetail {
  last_meeting_date: string | null
  avg_days_between_meetings: number | null
  relation: EmployeeRelation
  relation_employee: EmployeeOptionInterface
  request_employee: EmployeeOptionInterface
}

export enum OneToOneRelation {
  LineManager = 'line_manager',
  FunctionalManager = 'functional_manager',
}

export enum OneToOneEmployeeRelation {
  LineManager = 'line_manager',
  FunctionalManager = 'functional_manager',
  Report = 'report',
}

export interface Attendee extends EmployeeOptionInterface {
  department?: IdAndName
}

interface MeetingSeries {
  id: number
  event_id: string
  start: string
  end: string
  recurrence: string
}

export interface OneToOneMeeting {
  id: number
  manager: Attendee
  employee: Attendee
  relation_type: OneToOneRelation
  employee_relation_type: OneToOneEmployeeRelation
  meeting_series: MeetingSeries | null
}
